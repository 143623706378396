import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';

const RRIFCalculator = () => {
  const [age, setAge] = useState('');
  const [rrspBalance, setRrspBalance] = useState('');
  const [rrspReturnRate, setRrspReturnRate] = useState('');
  const [withdrawalAge, setWithdrawalAge] = useState('');
  const [rrifReturnRate, setRrifReturnRate] = useState('');
  const [customPercentage, setCustomPercentage] = useState('');
  const [projections, setProjections] = useState([]);
  const [showGrid, setShowGrid] = useState(false);


  const CRA_Min_Withdrawal_Rates = {
    71: 5.28,
    72: 5.40,
    73: 5.53,
    74: 5.67,
    75: 5.82,
    76: 5.98,
    77: 6.17,
    78: 6.36,
    79: 6.58,
    80: 6.82,
    81: 7.08,
    82: 7.38,
    83: 7.69,
    84: 8.04,
    85: 8.42,
    86: 8.84,
    87: 9.30,
    88: 9.80,
    89: 10.33,
    90: 10.96,
    91: 11.68,
    92: 12.52,
    93: 13.62,
    94: 15.00,
    95: 15.00,
  };

  const handleRRSPBalanceChange = (e) => {
    // Remove all non-numeric characters except the dot.
    let inputValue = e.target.value.replace(/[^\d.]/g, '');

    // Update state with the raw value for easier editing.
    setRrspBalance(inputValue);
};
  
const handleBlur = () => {
    let formattedValue = '';
    if (rrspBalance) {
        // Format only if the value is not empty.
        formattedValue = Number(rrspBalance).toLocaleString('en-CA', { style: 'currency', currency: 'CAD' });
    }
    setRrspBalance(formattedValue); // Update the formatted value when user finishes typing.
};
  

  const handleCalculate = () => {
    let Age = parseInt(age, 10);
    let WithdrawalAge = parseInt(withdrawalAge, 10) || 71;
    let Balance = parseFloat(rrspBalance.replace(/[^\d.-]/g, ''));
    let RRSPReturnRate = parseFloat(rrspReturnRate) / 100;
    let RRIFReturnRate = parseFloat(rrifReturnRate) / 100;
    let CustomPercentage = customPercentage ? parseFloat(customPercentage) / 100 : -1;
 
     // Ensure balance is a valid number
  if (isNaN(Balance) || Balance <= 0) {
    alert('Please enter a valid RRSP balance.');
    return;
  }  

    if (Age < 50 || Balance <= 0) {
      alert('Please enter valid details.');
      return;
    }

    let projectionsData = [];
    let CurrentYear = new Date().getFullYear();
    let StartYear = CurrentYear + (WithdrawalAge - Age);
    let RRIFBalance = 0;

    const currencyFormat = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });

    for (let i = WithdrawalAge; i <= 95; i++) {
      let Withdrawal = Balance * (CRA_Min_Withdrawal_Rates[i] / 100);
      let TotalWithdrawal = CustomPercentage > 0 ? Withdrawal + (Balance * CustomPercentage) : Withdrawal;
      let RemainingBalanceRRSP = (Balance - TotalWithdrawal) * (1 + RRSPReturnRate);
      let RemainingBalanceRRIF = (RRIFBalance + TotalWithdrawal) * (1 + RRIFReturnRate);

      projectionsData.push({
        year: StartYear,
        age: WithdrawalAge + (i - WithdrawalAge),
        CRA_Min_Withdrawal: CRA_Min_Withdrawal_Rates[i],
        additionalWithdrawal: CustomPercentage > 0 ? (Balance * CustomPercentage).toFixed(2) : 0,
        totalWithdrawal: TotalWithdrawal.toFixed(2),
        rrspBalance: RemainingBalanceRRSP.toFixed(2),
        rrifBalance: RemainingBalanceRRIF.toFixed(2),
      });

      Balance = RemainingBalanceRRSP;
      RRIFBalance = RemainingBalanceRRIF;
      StartYear++;
    }

    setProjections(projectionsData);
    setShowGrid(true);
  };

  const handleReset = () => {
    setAge('');
    setRrspBalance('');
    setRrspReturnRate('');
    setWithdrawalAge('');
    setRrifReturnRate('');
    setCustomPercentage('');
    setProjections([]);
    setShowGrid(false);
  };
  
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>RRIF Calculator</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Age"
            variant="outlined"
            fullWidth
            value={age}
            onChange={(e) => setAge(e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="RRSP Balance"
            variant="outlined"
            fullWidth
            value={rrspBalance}
            //onChange={(e) => setRrspBalance(e.target.value)}
            onChange={handleRRSPBalanceChange}
            onBlur={handleBlur}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="RRSP Return Rate (%)"
            variant="outlined"
            fullWidth
            value={rrspReturnRate}
            onChange={(e) => setRrspReturnRate(e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Withdrawal Age"
            variant="outlined"
            fullWidth
            value={withdrawalAge}
            onChange={(e) => setWithdrawalAge(e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="RRIF Return Rate (%)"
            variant="outlined"
            fullWidth
            value={rrifReturnRate}
            onChange={(e) => setRrifReturnRate(e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Optional Additional Withdrawal (%)"
            variant="outlined"
            fullWidth
            value={customPercentage}
            onChange={(e) => setCustomPercentage(e.target.value)}
            type="number"
          />
        </Grid>

        <Grid item xs={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCalculate}
          >
            Calculate
          </Button>
        </Grid>

      
      <Grid item xs={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {showGrid && (
        <Box sx={{ marginTop: 3 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Year</th>
                <th>Age</th>
                <th>CRA Min Withdrawal %</th>
                <th>Additional Withdrawal</th>
                <th>Total Withdrawal</th>
                <th>RRSP Balance</th>
                <th>RRIF Balance</th>
              </tr>
            </thead>
            <tbody>
              {projections.map((row, index) => (
                <tr key={index}>
                  <td>{row.year}</td>
                  <td>{row.age}</td>
                  <td>{row.CRA_Min_Withdrawal}</td>
                  <td>{row.additionalWithdrawal}</td>
                  <td>{row.totalWithdrawal}</td>
                  <td>{row.rrspBalance}</td>
                  <td>{row.rrifBalance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default RRIFCalculator;
