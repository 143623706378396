import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Box, Paper } from '@mui/material';

const SimulationForm = () => {
  const [winRate, setWinRate] = useState('50'); // Default Win Rate of 50%
  const [reward, setReward] = useState('2'); // Default Reward per Win
  const [risk, setRisk] = useState('1'); // Default Loss per Loss
  const [tradesPerMonth, setTradesPerMonth] = useState('10'); // Default Trades per Month
  const [months, setMonths] = useState(12); // Default Months (12)
  const [results, setResults] = useState(null);

   // Function to run the simulation
   const runSimulation = () => {
    const totalTrades = tradesPerMonth * months;
    let wins = 0, losses = 0;
    let totalReward = 0, totalLoss = 0;
    let cumulativeGainLoss = 0; // Initialize cumulative Gain/Loss to 0
    let maxWinRun = 0, maxLossRun = 0;
    let currentWinRun = 0, currentLossRun = 0;

    const resultsArray = [];

    for (let i = 1; i <= totalTrades; i++) {
      const randomValue = Math.random() * 100;
      const isWin = randomValue < parseFloat(winRate);

      if (isWin) {
        wins++;
        cumulativeGainLoss += parseFloat(reward); // Add reward for win
        totalReward += parseFloat(reward); // Add to total reward
        currentWinRun++;
        currentLossRun = 0;
        maxWinRun = Math.max(maxWinRun, currentWinRun);
      } else {
        losses++;
        cumulativeGainLoss -= parseFloat(risk); // Subtract risk for loss
        totalLoss += parseFloat(risk); // Add to total loss
        currentLossRun++;
        currentWinRun = 0;
        maxLossRun = Math.max(maxLossRun, currentLossRun);
      }


      const roi = totalLoss > 0 ? ((totalReward - totalLoss) / totalLoss) * 100 : 100;

      resultsArray.push({
        trade: i,
        outcome: isWin ? 'Win' : 'Loss',
        gainLoss: cumulativeGainLoss.toFixed(2),
        cumulativeLoss: totalLoss.toFixed(2),
        cumulativeReward: totalReward.toFixed(2),
        roi: roi.toFixed(2)
      });
    }

    setResults({
      totalTrades,
      wins,
      losses,
      totalReward,
      totalLoss,
      roi: (totalReward - totalLoss).toFixed(2),
      maxWinRun,
      maxLossRun,
      resultsArray
    });
  };

  const resetForm = () => {
    setWinRate('50');
    setReward('100');
    setRisk('50');
    setTradesPerMonth('20');
    setMonths(12);
    setResults(null);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Simulation Settings
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Win Rate (%)"
              variant="outlined"
              fullWidth
              value={winRate}
              onChange={(e) => setWinRate(e.target.value.replace('%', ''))} // Remove '%' if typed manually
              margin="normal"
              type="number"
              InputProps={{
                endAdornment: <span>%</span>, // Append '%' symbol automatically
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Reward per Win"
              variant="outlined"
              fullWidth
              value={reward}
              onChange={(e) => setReward(e.target.value)}
              margin="normal"
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Loss per Loss"
              variant="outlined"
              fullWidth
              value={risk}
              onChange={(e) => setRisk(e.target.value)}
              margin="normal"
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Trades per Month"
              variant="outlined"
              fullWidth
              value={tradesPerMonth}
              onChange={(e) => setTradesPerMonth(e.target.value)}
              margin="normal"
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Months"
              variant="outlined"
              fullWidth
              value={months}
              onChange={(e) => setMonths(parseInt(e.target.value))}
              margin="normal"
              type="number"
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={runSimulation}>
            Run Simulation
          </Button>
          <Button variant="outlined" color="secondary" onClick={resetForm}>
            Reset
          </Button>
        </Box>
      </Box>

      {results && (
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            {/* Results Summary Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom>
                  Simulation Results
                </Typography>
                <Typography><strong>Total Trades:</strong> {results.totalTrades}</Typography>
                <Typography><strong>Wins:</strong> {results.wins}</Typography>
                <Typography><strong>Losses:</strong> {results.losses}</Typography>
                <Typography><strong>Total Gain:</strong> {results.totalReward}</Typography>
                <Typography><strong>Total Loss:</strong> {results.totalLoss}</Typography>
                <Typography><strong>ROI:</strong> {results.roi}</Typography>
                <Typography><strong>Max Win Streak:</strong> {results.maxWinRun}</Typography>
                <Typography><strong>Max Loss Streak:</strong> {results.maxLossRun}</Typography>
              </Paper>
            </Grid>

            {/* Trade Breakdown Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
                <Typography variant="h6" sx={{ mb: 3 }}>Trade Breakdown</Typography>

                <Grid container sx={{ mb: 2 }}>
                  {/* Column Headers with Fixed Widths */}
                  <Grid item xs={1.5}><strong>Trade #</strong></Grid>
                  <Grid item xs={2}><strong>Outcome</strong></Grid>
                  <Grid item xs={2}><strong>Gain/Loss</strong></Grid>
                  <Grid item xs={2}><strong>Total Loss</strong></Grid>
                  <Grid item xs={2}><strong>Total Gain</strong></Grid>
                  <Grid item xs={2}><strong>ROI (%)</strong></Grid>
                </Grid>

                <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                  {results.resultsArray.map((result, index) => (
                    <Grid container key={index} sx={{ mb: 1 }}>
                      <Grid item xs={1.5} sx={{ padding: '8px', textAlign: 'center' }}>{result.trade}</Grid>
                      <Grid item xs={2} sx={{ padding: '8px', textAlign: 'center' }}>{result.outcome}</Grid>
                      <Grid item xs={2} sx={{ padding: '8px', textAlign: 'center' }}>{result.gainLoss}</Grid>
                      <Grid item xs={2} sx={{ padding: '8px', textAlign: 'center' }}>{result.cumulativeLoss}</Grid>
                      <Grid item xs={2} sx={{ padding: '8px', textAlign: 'center' }}>{result.cumulativeReward}</Grid>
                      <Grid item xs={2} sx={{ padding: '8px', textAlign: 'center' }}>{result.roi}%</Grid>
                    </Grid>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default SimulationForm;





// import Card from "react-bootstrap/Card";
// import { Button } from "react-bootstrap";
// import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

// const TradeSimulator = () => {
//   const [balance, setBalance] = useState(1000);
//   const [tradeHistory, setTradeHistory] = useState([]);

//   const makeTrade = (type) => {
//     const amount = Math.floor(Math.random() * 200) - 100; // Random profit/loss
//     const newBalance = balance + amount;
//     setBalance(newBalance);
//     setTradeHistory([...tradeHistory, { time: new Date().toLocaleTimeString(), balance: newBalance }]);
//   };

//   return (
//     <div className="p-6 max-w-lg mx-auto">
//       <Card>
//         <Card.Body className="p-4 text-center">
//           <h2 className="text-xl font-bold">Trade Simulator</h2>
//           <p className="text-lg my-4">Balance: ${balance}</p>
//           <div className="flex justify-center gap-4">
//             <Button onClick={() => makeTrade("buy")} className="bg-green-500">Buy</Button>
//             <Button onClick={() => makeTrade("sell")} className="bg-red-500">Sell</Button>
//           </div>
//         </Card.Body>
//       </Card>
//       <div className="mt-6">
//         <h3 className="text-lg font-bold">Trade History</h3>
//         <ResponsiveContainer width="100%" height={300}>
//           <LineChart data={tradeHistory}>
//             <XAxis dataKey="time" />
//             <YAxis domain={[0, 'dataMax']} />
//             <Tooltip />
//             <Line type="monotone" dataKey="balance" stroke="#8884d8" />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );
// };

// export default TradeSimulator;