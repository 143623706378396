import React, { useState } from "react";
import CurrencyConverter from "./CurrencyConverter"; // Import your React app
import { FaMoneyBillWave, FaBriefcase, FaDatabase } from "react-icons/fa"; // Font Awesome icons
import SalaryCalculator from "./SalaryCalculator";
import TradesSimulator from "./TradesSimulator";
import RRSPTracker from "./RRSPTracker";

function DemoWebApps() {
  const [selectedCategory, setSelectedCategory] = useState(""); // Track selected category
  const [selectedApp, setSelectedApp] = useState(""); // Track selected app
  const [isAppOpen, setIsAppOpen] = useState(false); // Track whether the app is open or closed

  // Demo app URLs categorized
  const demoApps = {
    finance: [
      { name: "Currency Converter", type: "react", component: <CurrencyConverter />}, // React app
      { name: "Trades Simulator", type: "react", component: <TradesSimulator />},
      //{ name: "Trades Simulator", url: "https://yrcgi.dev/trades-simulator", type: "unigui" },
      //{ name: "Trades Simulator", url: "https://yellowray.dev/trades-simulator", type: "unigui" },
      //{ name: "RRSP to RRIF Tracker", url: "https://yrcgi.dev/rrsp-tracker", type: "unigui" },
      //{ name: "RRSP to RRIF Tracker", url: "https://yellowray.dev/rrsp-tracker", type: "unigui" },
      { name: "RRSP to RRIF Tracker", type: "react", component: <RRSPTracker /> },
    ],
    business: [
      // Temporary placeholder, we'll show "Coming Soon" for now
      { 
        name: "Salary Calculator", 
        type: "react", 
        component: <SalaryCalculator isAppOpen={isAppOpen} setIsAppOpen={setIsAppOpen} /> 
      },
    ],
    dataResearch: [
      //{ name: "Data Analysis Tool", url: "http://localhost:8087/data", type: "unigui" },
    ],
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedApp(""); // Reset app selection when switching categories
  };

  const handleAppClick = (app) => {
    if (app.type === "react") {
      setSelectedApp(app.name);
      setIsAppOpen(true); 
    } else if (app.type === "unigui") {
      if (selectedApp !== app.url) {
        setSelectedApp(app.url);
        setIsAppOpen(true);
      }
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div className="w-64 bg-blue-800 text-white p-4 space-y-6 flex-shrink-0 shadow-lg">
        <h2 className="text-xl font-bold text-yellow-400">Yellow Ray Demo Dashboard</h2>
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => handleCategoryClick("business")}
              className="flex items-center w-full text-left text-white font-bold px-4 py-2 hover:bg-blue-700 rounded transition"
            >
              <FaBriefcase className="mr-2" />
              Business
            </button>
          </li>
          <li>
            <button
              onClick={() => handleCategoryClick("finance")}
              className="flex items-center w-full text-left text-white font-bold px-4 py-2 hover:bg-blue-700 rounded transition"
            >
              <FaMoneyBillWave className="mr-2" />
              Finance
            </button>
          </li>
          <li>
            <button
              onClick={() => handleCategoryClick("dataResearch")}
              className="flex items-center w-full text-left text-white font-bold px-4 py-2 hover:bg-blue-700 rounded transition"
            >
              <FaDatabase className="mr-2" />
              Data/Research
            </button>
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 p-8 bg-gray-50 text-gray-800">
        <h2 className="text-3xl font-bold mb-6 text-blue-900">
          {selectedCategory ? `${selectedCategory[0].toUpperCase()}${selectedCategory.slice(1)} Demos` : "Welcome!"}
        </h2>

        {/* Show list of apps for the selected category */}
        {selectedCategory && selectedCategory === "webapps" && (
          <div className="text-center text-gray-500 text-lg italic mb-6">
            Coming Soon! We're working on these exciting web applications.
          </div>
        )}

        {selectedCategory && selectedCategory === "dataResearch" && (
          <div className="text-center text-gray-500 text-lg italic mb-6">
            Coming Soon! Stay tuned for exciting data and research tools.
          </div>
        )}

        {/* Show list of apps for the selected category */}
        {selectedCategory && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            {demoApps[selectedCategory]?.map((app, index) => (
              <button
                key={index}
                onClick={() => handleAppClick(app)}
                className="px-6 py-4 bg-blue-600 text-white font-bold rounded-lg shadow-lg hover:bg-yellow-500 transition-all duration-200"
              >
                {app.name}
              </button>
            ))}
          </div>
        )}

        {/* Show blank state if no category is selected */}
        {!selectedCategory && (
          <div className="text-gray-500 text-lg italic">
            Select a category from the sidebar to view available demos.
          </div>
        )}

        {isAppOpen && selectedApp === "Currency Converter" && (
          <CurrencyConverter isAppOpen={isAppOpen} setIsAppOpen={setIsAppOpen} />
        )}

        {isAppOpen && selectedApp === "Salary Calculator" && (
          <SalaryCalculator isAppOpen={isAppOpen} setIsAppOpen={setIsAppOpen} />
        )}

        {isAppOpen && selectedApp === "Trades Simulator" && (
          <TradesSimulator isAppOpen={isAppOpen} setIsAppOpen={setIsAppOpen} />
        )}

        {isAppOpen && selectedApp === "RRSP to RRIF Tracker" && (
          <RRSPTracker isAppOpen={isAppOpen} setIsAppOpen={setIsAppOpen} />
        )}

          {selectedApp && selectedApp !== "Currency Converter" && selectedApp !== "Salary Calculator" && selectedApp !== "Trades Simulator" && selectedApp !== "RRSP to RRIF Tracker" && (
          <div className="w-full flex justify-center">
            <iframe
              src={
                   selectedApp === "RRSP to RRIF Tracker"
                  ? "https://yrcgi.dev/rrsp-tracker"
                  : selectedApp // Fallback to the default URL for any other uniGUI app
              }
              width="100%"
              height="800px"
              title="Demo App"
              className="rounded-lg shadow-lg border-2 border-gray-200"
              sandbox="allow-scripts allow-forms allow-same-origin allow-modals"
            ></iframe>
          </div>
        )}  

      </div>
    </div>
  );
}

export default DemoWebApps;